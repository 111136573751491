import React from "react";

const PageTitleGallery = () => {
  return (
    <div id="page-title">
      <div className="wrapper text-center">
        <h1 className="large text-uppercase">Galería</h1>
      </div>
    </div>
  );
};

export default PageTitleGallery;
